// firebase.js

import firebase from 'firebase/compat/app'; // Use 'compat/app' for Firebase v9 and above
import 'firebase/compat/storage';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyCG-E9RGisyat4Q9Re0cDNRC2-qfP6lOcc",
    authDomain: "videoqr-23258.firebaseapp.com",
    projectId: "videoqr-23258",
    storageBucket: "videoqr-23258.appspot.com",
    messagingSenderId: "653262638187",
    appId: "1:653262638187:web:0a23f27b50296bff9ab52e",
    measurementId: "G-Q2XGB043Q9"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const database = firebase.database();

export { storage, database, firebase as default };
